import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'
import { createApp } from '@vue/composition-api'
import Amplify from 'aws-amplify'
import Vue from 'vue'

import './vue-composition-api.js'
import App from './App.vue'
import './registerServiceWorker'
import awsExports from './aws-exports'
import router from './router'
import store from './store'
import './bootstrap-vue'
import './vee-validate'
import { createProvider } from './vue-apollo'
import i18n from './vue-i18n'
import './dayjs'
import './vue-virtual-scroller'

require('./assets/scss/global.scss')

// mock-apiをimportするとmockデータ取得
// import '../tests/mocks/mock-api'

// for amplify
Amplify.configure(awsExports)

// for ampliy vue components
applyPolyfills().then(() => {
  defineCustomElements(window)
})
Vue.config.ignoredElements = [/amplify-\w*/]

Vue.config.productionTip = false

createApp({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: (h: any) => h(App),
}).mount('#app')
