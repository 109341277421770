import Vue from 'vue'
import routes from 'vue-auto-routing'
import Router, { Route } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'

import store from './store'
import { AVAILABLE_PATH } from './utils/constant'

Vue.use(Router)

const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes,
    },
  ],
})

console.log('router initialize')

router.beforeEach(async (to, from, next) => {
  console.log('router beforeEach')
  const isRequiredAuth = to.matched.some((record) => record.meta.requiresAuth)

  console.log('to.fullPath=', to.fullPath)

  // 認証不要なのでそのまま
  if (!isRequiredAuth) {
    console.log('- is not required auth')
    next()
    return
  }

  let noAuth = false
  try {
    // TODO always return not authenticated
    // https://github.com/aws-amplify/amplify-js/issues/5167
    if (!store.state.auth.authorization) {
      // localStorage に有効なセッションを持っているか
      await store.dispatch('auth/checkAlreadyLogin')
      if (store.state.auth.authorization) {
        console.log('- session already has')
        // await store.dispatch('app/getUserSystemInfo')
      } else {
        noAuth = true
      }
    }
  } catch (err) {
    console.log('- couldnt get auth/systeminfo')
    console.log(err)
    noAuth = true
  }

  // 有効なセッションがなかった場合にはそのまま遷移（遷移先でログイン画面を表示）
  if (noAuth) {
    if (isRedirectCandidate(to)) {
      console.log('- redirect to home with no auth')
      next({ path: redirectTop() })
      return
    } else {
      console.log('- redirect to next with no auth')
      next()
    }
    return
  }

  try {
    // メンテナンス中
    await store.dispatch('app/getSystemStatus')
    if (store.getters['app/isSystemMaintenance']) {
      if (to.fullPath === '/c/maintenance') {
        next() // 同じURLでも path 指定するとリダイレクトするのを回避
      } else {
        next({ path: '/c/maintenance' })
      }
      return
    }

    // 正常時
    // パス未指定の場合、またはメンテ画面の場合は権限に応じたリダイレクト
    if (isRedirectCandidate(to)) {
      console.log('- redirect to home')
      next({ path: redirectTop() })
      return
    }
    // メインパターン
    next()
    return
  } catch (e) {
    // システム情報が取得できないのでメンテモード
    // next({ path: '/c/maintenance' })
    next()
  }
})

const redirectList = ['/', '/c/maintenance']
/**
 * リダイレクト対象
 * @param to
 * @returns
 */
function isRedirectCandidate(to: Route) {
  return redirectList.includes(to.fullPath)
}

/**
 * 権限に応じてトップ（ホーム）に移動
 * @param next
 */
export function redirectTop() {
  if (store.getters['app/hasHeadRight']) {
    return AVAILABLE_PATH.head.slice(0, -1)
  } else {
    return AVAILABLE_PATH.shop.slice(0, -1)
  }
}

export default router
