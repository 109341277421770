import { Translations } from '@aws-amplify/ui-components'
import { I18n } from 'aws-amplify'
import dayjs from 'dayjs'
import deepmerge from 'deepmerge'
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages: LocaleMessages = {}
  locales.keys().forEach((key) => {
    // loaded order
    // - ja.json
    // - ja_api.json
    // - ja_x.json
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      let locale = matched[1]
      // <locale>_ex.json の読み込み
      const localeEx = locale.split('_')
      if (localeEx.length > 1) {
        locale = localeEx[0]
      }
      const cv = Object.assign({}, messages[locale]) || {}
      // messages[locale] = Object.assign(cv, locales(key))
      messages[locale] = deepmerge(cv, locales(key))
      // messages[locale] = locales(key)
    }
  })
  return messages
}

dayjs.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
})

I18n.putVocabulariesForLanguage('ja', {
  [Translations.BACK_TO_SIGN_IN]: 'ログイン画面に戻る',
  [Translations.USERNAME_LABEL]: 'ユーザ名 *',
  [Translations.USERNAME_PLACEHOLDER]: 'ユーザ名を入力してください',
  [Translations.EMAIL_LABEL]: 'メールアドレス *',
  [Translations.EMAIL_PLACEHOLDER]: 'メールアドレスを入力してください',
  // login
  [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れの場合',
  [Translations.PASSWORD_LABEL]: 'パスワード *',
  [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力してください',
  [Translations.RESET_PASSWORD_TEXT]: 'パスワードを再設定する',
  [Translations.SIGN_IN_ACTION]: 'ログイン',
  [Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
  // forgot password
  [Translations.RESET_YOUR_PASSWORD]: 'パスワード再設定',
  [Translations.SEND_CODE]: '送信',
  [Translations.CODE_LABEL]: '確認用コード',
  [Translations.CODE_PLACEHOLDER]: '確認用コードを入力してください',
  [Translations.NEW_PASSWORD_LABEL]: '新パスワード',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: '新しいパスワードを入力してください',
  [Translations.SUBMIT]: '送信',
  // change password
  [Translations.CHANGE_PASSWORD_ACTION]: '更新',
  [Translations.CHANGE_PASSWORD]: 'パスワード変更',
  // MFA
  [Translations.TOTP_HEADER_TEXT]:
    'スキャンしてセキュリティコードを入力してください',
  [Translations.TOTP_LABEL]: 'セキュリティコードを入力してください',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: '送信',
  [Translations.CONFIRM_TOTP_CODE]: 'ワンタイムパスワード',
  [Translations.CONFIRM]: '送信',

  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'メールアドレスに確認用コードを送信する',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: '確認用コードを入力してください',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: '電話番号',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: '確定',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: '確認用コードを送信',

})

export const COGNITO_CODE_MAP = {
  signIn_failure: {
    UserNotFoundException: 'error.invalidAddressOrPassword',
    NotAuthorizedException: 'error.invalidAddressOrPassword',
    PasswordResetRequiredException: 'error.PasswordResetRequired',
    LimitExceededException: 'error.limitExceeded',
    InvalidParameterException: 'error.invalidPassword',
  },
  forgotPassword_failure: {
    UserNotFoundException: 'error.userNotFound',
    InvalidPasswordException: 'error.invalidPassword',
    LimitExceededException: 'error.limitExceeded',
    InvalidParameterException: 'error.forgotPasswordfailureInvalidParameter',
  },
  completeNewPassword_failure: {
    InvalidPasswordException: 'error.invalidPassword',
  },
  forgotPasswordSubmit_failure: {
    CodeMismatchException: 'error.codeMismatch',
    InvalidPasswordException: 'error.invalidPassword',
    LimitExceededException: 'error.limitExceeded',
  },
}

// ブラウザロケールが初期
export default new VueI18n({
  locale: 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})
