const awsmobile = {
  Auth: {
    // フェデレーションアイデンティティのID
    identityPoolId: 'ap-northeast-1:943b8e0e-3ada-4552-a6e4-3b9509788611',
    // リージョン
    region: 'ap-northeast-1',
    // ユーザープールのID
    userPoolId: 'ap-northeast-1_scrjYmeo7',
    // ユーザープールのウェブクライアントID
    userPoolWebClientId: '7alrerc4059em60mhu7kni9qn5',
    // mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'order-real-ui-api',
        // eslint-disable-next-line
        endpoint: 'https://api.real.order.genky-aws-stg.com/b1/graphql',
        region: 'ap-northeast-1',
      },
    ],
  },
}
export default awsmobile
