import { Auth } from 'aws-amplify'

import { AuthState, AuthMutations, Actions } from '@/types/entities'
import { onLogout } from '@/vue-apollo'
import i18n from '@/vue-i18n'

function getDefaultState() {
  return {
    // Variable value
    loadingIds: [],
    authorization: false,
    loginUserInfo: {
      username: '',
    },
  }
}
const state: AuthState = getDefaultState()

const getters = {
  isChangePasswordLoading(state: AuthState) {
    const ret = !state.loadingIds.indexOf('changePassword')
    return ret
  },
}

const mutations: AuthMutations = {
  startLoading: (state: AuthState, Id: string) => {
    state.loadingIds.push(Id)
  },
  endLoading: (state: AuthState, Id: string) => {
    state.loadingIds = state.loadingIds.filter(function (Ids: string) {
      return Ids !== Id
    })
  },

  signin: (state: AuthState, userInfo) => {
    state.authorization = true
    state.loginUserInfo = userInfo
  },

  setUserAttribute: (state: AuthState, attributes) => {
    state.loginUserInfo.attributes = attributes
  },

  logout: (state: AuthState) => {
    Object.assign(state, getDefaultState())
  },

  // updateSession: (state: AuthState, session) => {
  //   console.log(session)
  //   state.loginUserInfo.signInUserSession = session
  // },
}

const actions: Actions = {
  startRequest: ({ commit }, Id: string) => {
    // console.log("startRequest", Id)
    commit('startLoading', Id)
  },
  endRequest: ({ commit }, Id: string) => {
    // console.log("endRequest", Id)
    commit('endLoading', Id)
  },

  alreadySignedIn: ({ commit }, user) => {
    commit('signin', user)
  },

  async changePassword({ dispatch, commit, state: AuthState }, passwordInfo) {
    const loadingId = 'changePassword'
    try {
      dispatch('startRequest', loadingId)
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.changePassword(
        user,
        passwordInfo.currentPassword,
        passwordInfo.newPassword
      )
      console.log(`change password is succeeded: ${result}`)
      dispatch('endRequest', loadingId)
      return result
    } catch (error) {
      dispatch('endRequest', loadingId)
      console.log(`change password is failed: ${error.code}`)
      if (error.code === 'NotAuthorizedException') {
        throw Error(i18n.t('error.invalidChangePasswordNotAuth') as string)
      } else if (error.code === 'LimitExceededException') {
        throw Error(i18n.t('error.limitExceeded') as string)
      } else if (error.code === 'InvalidParameterException') {
        throw Error(i18n.t('error.invalidPassword') as string)
      } else {
        throw error
      }
    }
  },

  async logout({ commit }) {
    try {
      const result = await Auth.signOut()
      onLogout()
      commit('logout')
      commit('app/logout', null, { root: true })
      return result
    } catch (e) {
      console.log(e)
      return e
    }
  },

  async checkAlreadyLogin({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      commit('signin', user)
    } catch (e) {
      console.log(e)
      return e
    }
  },

  // async updateCurrentSession({ commit }) {
  //   try {
  //     const session = await Auth.currentSession()
  //     commit('updateSession', session)
  //   } catch (e) {
  //     console.log(e)
  //     return e
  //   }
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
