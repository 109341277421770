import { DataProxy, FetchResult } from '@apollo/client'
import { ApolloError } from 'apollo-client'
import { Dayjs } from 'dayjs'

import Toast, { genNotifyMessage } from './toast'

import store from '@/store'
import {
  BulkOperationRunQueryDocument,
  CreateUploadUrlDocument,
  CreateUploadUrlInput,
  BulkOperationRunQueryInput,
  RunSimpleImportDocument,
  RunSimpleImportInput,
  SystemSetting,
  UserInformation,
  UserSystemInfoDocument,
} from '@/types/generated/graphql'
import { apolloClient } from '@/vue-apollo'

type QueryUserSysInfoResponse = {
  userInformation: UserInformation
  systemSetting: SystemSetting
}

export default {
  async mutationCreateUploadUrl(
    input: CreateUploadUrlInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: CreateUploadUrlDocument,
      variables: { input },
    })
  },
  async mutationRunSimpleImport(
    input: RunSimpleImportInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: RunSimpleImportDocument,
      variables: { input },
    })
  },
  async bulkOperationRunQuery(
    input: BulkOperationRunQueryInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: BulkOperationRunQueryDocument,
      variables: { input },
    })
  },
  async getUserSysInfo(self: any) {
    const setUserSysInfo = (data: QueryUserSysInfoResponse) => {
      // const { availableStores: ass, primaryStoreCd } = data.userInformation
      store.commit('app/userSystemInfo', data)
      // store.commit('app/updateBelongStore', ass)

      // 未選択の場合のみ（すでに選択済みの場合は変更しない
      console.log(location.pathname)
      // if (store.state.app.selectedStoreCode === '') {
      //   // URL直のケース, 指定されたものそのまま
      //   // 所属しているかいないかは関係ない（エラーになるだけ）。
      //   let storeCd = ''
      //   const storeCdFromPathname = getStoreCdFromPathname()
      //   if (storeCdFromPathname) {
      //     storeCd = storeCdFromPathname
      //   } else {
      //     storeCd = ''
      //     if (primaryStoreCd) {
      //       storeCd = primaryStoreCd
      //     } else if (ass && ass.length > 0 && ass[0]) {
      //       storeCd = ass[0].storeCd || ''
      //     }
      //   }
      //   store.dispatch('app/updateSelectedStoreCode', { storeCode: storeCd })
      // }
    }
    const errorHandler = (e: any) => {
      Toast.error(self, {
        message: genNotifyMessage('e.get', 'user-info', e as ApolloError),
      })
    }
    // 非同期的に定期的に更新
    const result = await apolloClient.watchQuery<QueryUserSysInfoResponse>({
      query: UserSystemInfoDocument,
      pollInterval: 1000 * 60 * 60,
    })
    console.log('bar')
    console.log(result)
    result.subscribe(
      ({ data }) => setUserSysInfo(data),
      (e: any) => errorHandler(e)
    )
  },
}
