import { ApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'
import { TranslateResult } from 'vue-i18n'

import i18n from '@/vue-i18n'

/**
 * バックエンド側のエラー取得
 * 1. graphql error
 * 2. network error
 * 3. 他
 * 4. 予期しないエラー
 * @param e
 */
interface includeGraphQLError {
  message?: string
  graphQLErrors: readonly GraphQLError[]
  networkError: Error | null
}

type RealValidationError = {
  code: string
  meta: {
    [key: string]: string
  }
}
type Extensions = {
  validationErrors: RealValidationError[]
}
export function createApolloErrorMessage<T extends includeGraphQLError>(e: T) {
  console.log(e)
  let returnMsg = i18n.t('notification.e.unexpected', []) as string
  try {
    if (e.graphQLErrors.length > 0) {
      const msgArr: (string | TranslateResult)[] = []

      e.graphQLErrors.forEach((i) => {
        const ex = i.extensions as Extensions
        ex.validationErrors.forEach((i) => {
          if (i18n.te(`api.${i.code}`)) {
            const meta = getTranslatedMeta(i.meta)
            msgArr.push(i18n.t(`api.${i.code}`, meta))
          }
        })
      })
      if (msgArr.length > 0) {
        returnMsg = msgArr.join('\r\n')
      }
    } else if (e.networkError) {
      const nErr = i18n.t('notification.e.network') as string
      returnMsg = i18n.t('notification.e.unexpected', [nErr]) as string
    } else if (e.message) {
      returnMsg = returnMsg + e.message
    }
  } catch {
    // do nothing
  }
  return returnMsg
}

/**
 * レスポンスの meta を翻訳済みリソースに変換
 * @param orgMeta
 * @returns
 */
type TranslatedMeta = { [key: string]: string | TranslateResult }
export function getTranslatedMeta(orgMeta: {
  [key: string]: string
}): TranslatedMeta {
  const meta: TranslatedMeta = {}
  for (const key in orgMeta) {
    let value: string | TranslateResult = orgMeta[key]
    if (i18n.te(`attribute.${key}${value}`)) {
      value = i18n.t(`attribute.${key}${value}`)
    }
    meta[key] = value
  }
  return meta
}
