import { InMemoryCache } from '@apollo/client'
import { Auth } from 'aws-amplify'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

import awsExports from './aws-exports'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
// const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000'
const httpEndpoint =
  awsExports.API.endpoints[0].endpoint || 'http://localhost:4000'

export const cache = new InMemoryCache({
  typePolicies: {
    UiStoreRevicedForecastedCustomers: {
      keyFields: ['storeCd', 'targetDate', 'editedDate'],
    },
    UiStoreOrderQuantity: {
      keyFields: ['storeCd', 'itemCd', 'deliveryDate'],
    },
    UiNormalOrderDispItem: {
      keyFields: ['storeCd', 'itemCd'],
    },
    UiNormalOrderDispItemDailyInfo: {
      keyFields: ['storeCd', 'itemCd', 'deliveryDate'],
    },
    UiStoreRevicedInventory: {
      keyFields: ['storeCd', 'itemCd', 'targetDate'],
    },
    UiCustOrderHoldStock: {
      keyFields: ['storeCd', 'orderId'],
    },
    UiSelectedEndShelfPlan: {
      keyFields: ['storeCd', 'endShelfCd', 'planStartDate'],
    },
  },
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: '',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache
  cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...
  getAuth: defaultGetAuth,

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// @ts-ignore
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
})

// Call this in the Vue app file
export function createProvider(options = {}) {
  // @ts-ignore
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    },
  })

  return apolloProvider
}

// // Manually call this when user log in
// export async function onLogin(apolloClient: ApolloClient<any>, token: string) {
//   if (typeof localStorage !== 'undefined' && token) {
//     localStorage.setItem(AUTH_TOKEN, token)
//   }
//   // dont use websocket
//   // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log(
//       '%cError on cache reset (login)',
//       'color: orange;',
//       (e as Error).message
//     )
//   }
// }

// Manually call this when user log out
export async function onLogout() {
  // dont use websocket
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.stop()
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError on cache reset (logout)',
      'color: orange;',
      (e as Error).message
    )
  }
}

/**
 * Authorization header の設定
 * @returns
 */
async function defaultGetAuth() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.signInUserSession
    const token = currentSession.idToken.getJwtToken()
    return token ? `Bearer ${token}` : ''
  } catch (e) {
    console.info('error getting jwt', e)
  }
}

/**
 * Query のキャッシュID取得
 * @param queryName `query name : ex uiNormalOrderDispItems`
 * @param queryParam request params
 * @returns
 */
export function getQueryCacheId(queryName: string, queryParam: any) {
  const q = `${queryName}(${JSON.stringify(queryParam, stringifyReplacer)})`
  return q
}
export function getCustomCacheId(queryName: string, queryParam: any) {
  const q = `${queryName}:${JSON.stringify(queryParam)}`
  return q
}
function stringifyReplacer(_key: string, value: any): any {
  if (value && typeof value === 'object' && !Array.isArray(value)) {
    value = Object.keys(value)
      .sort()
      .reduce((copy, key) => {
        copy[key] = value[key]
        return copy
      }, {} as Record<string, any>)
  }
  return value
}

/**
 * キャッシュの削除
 * 特定IDを消したい場合は ID を指定、Query 全体なら ROOT_QUERY
 * @param id
 * @param fieldName
 */
export function removeCache(opt: { id: string; fieldName?: string }) {
  cache.evict(opt)
}
