import { ApolloError } from 'apollo-client'
import dayjs from 'dayjs'
import { GraphQLError } from 'graphql'
import { ActionTree, GetterTree } from 'vuex'

import store from '..'
import {
  AppState,
  RootState,
  AppMutations,
  AvailableStore,
} from '../../types/entities'

import router from '@/router'
import {
  SystemSetting,
  SystemStatus,
  SystemStatusDocument,
  UserInformation,
  UserSystemInfoDocument,
} from '@/types/generated/graphql'
import Toast, { genNotifyMessage } from '@/utils/toast'
import { apolloClient } from '@/vue-apollo'

type QueryUserSysInfoResponse = {
  userInformation: UserInformation
  systemSetting: SystemSetting
}
type QuerySystemStatusResponse = {
  systemStatus: SystemStatus
}

type UserSystemInfo = {
  userInformation: UserInformation
  systemSetting: SystemSetting
}

// 推奨状況のキー これが異常の場合は推奨数異常でアラート
const EXEC_STATUS_RECOMMEND_KEY = 'out_recommend_status'

function getDefaultState() {
  return {
    userInfo: null,
    systemSetting: null,
    systemStatus: {
      isMaintenance: false,
      isImportWarn: false,
      isExtServiceWarn: false,
    },
    loadingIds: [],
    belongStores: [],
    selectedStoreCode: '',
  }
}
export const state: AppState = getDefaultState()

export const mutations: AppMutations = {
  userSystemInfo: (state: AppState, userSystemInfo: UserSystemInfo) => {
    state.userInfo = userSystemInfo.userInformation
    state.systemSetting = userSystemInfo.systemSetting
  },
  updateSelectedStoreCode(state: AppState, storeCode: string) {
    console.log(`mutation updateSelectedStoreCode: ${storeCode}`)
    state.selectedStoreCode = storeCode
  },
  updateBelongStore: (state: AppState, vals: AvailableStore[]) => {
    state.belongStores = vals
  },
  updateSystemStatus(state: AppState, systemStatus: SystemStatus) {
    // @ts-ignore
    state.systemStatus = systemStatus
  },
  logout: (state: AppState) => {
    Object.assign(state, getDefaultState())
  },
  startLoading: (state: AppState, Id: string) => {
    state.loadingIds.push(Id)
  },
  endLoading: (state: AppState, Id: string) => {
    state.loadingIds = state.loadingIds.filter(function (Ids: string) {
      return Ids !== Id
    })
  },
}

const getters: GetterTree<AppState, RootState> = {
  baseDate(state: AppState) {
    if (state.systemSetting) {
      return state.systemSetting.systemDate
    } else {
      console.log(dayjs().format('YYYY-MM-DD'))
      return dayjs().format('YYYY-MM-DD')
    }
  },
  displayMaintenanceWord1(state: AppState) {
    if (state.systemSetting) {
      // @ts-ignore
      return state.systemSetting.displayMaintenanceWord1
    } else {
      return ''
    }
  },
  displayMaintenanceWord2(state: AppState) {
    if (state.systemSetting) {
      // @ts-ignore
      return state.systemSetting.displayMaintenanceWord2
    } else {
      return ''
    }
  },
  enableCategoryHierarchy(state: AppState) {
    if (state.systemSetting) {
      return state.systemSetting.enableCategoryHierarchy
    } else {
      return 5
    }
  },
  getCategoryInCharge(state: AppState) {
    if (state.belongStores.length === 0) {
      return null
    }
    return state.belongStores.find((i) => i.storeCd === state.selectedStoreCode)
  },
  highlightSalePromoPlanType(state: AppState): number {
    if (state.systemSetting && state.systemSetting.highlightSalePromoPlanType) {
      return state.systemSetting.highlightSalePromoPlanType
    } else {
      return 0
    }
  },
  availablePaths(state: AppState) {
    if (!state.userInfo || !state.userInfo.availablePaths) {
      return ['/c/']
    }
    const paths = state.userInfo.availablePaths
    const result = paths
      .map((p) => (p ? p.path : null))
      .filter((i): i is NonNullable<typeof i> => Boolean(i))
    result.push('/c/')
    return result
  },
  hasShopRight(state: AppState) {
    if (!state.userInfo || !state.userInfo.availablePaths) return false
    return state.userInfo.availablePaths.some((i) => i && i.path === '/s/') //店舗権限
  },
  hasHeadRight(state: AppState) {
    if (!state.userInfo || !state.userInfo.availablePaths) return false
    return state.userInfo.availablePaths.some((i) => i && i.path === '/h/') //本部権限
  },
  hasAdminRight(state: AppState) {
    if (!state.userInfo) return false
    return state.userInfo.isAdministrator //システム管理者権限
  },
  selectedStoreCode(sate: AppState) {
    return state.selectedStoreCode
  },
  /**
   * アクセス可否（ユーザ/システム情報の取得は保証しない）
   */
  canAccessRight: (state: AppState, getters) => (toPath: string) => {
    const availablePaths: string[] = getters.availablePaths
    const p = toPath === '/' ? '/' : `/${toPath.split('/')[1]}/`
    console.log(`availablePaths: ${availablePaths}`)
    console.log(`next: ${p}`)
    return availablePaths.includes(p)
  },
  /**
   * ユーザ/システム情報取得済み & アクセス可能
   */
  canAccess: (state: AppState, getters) => (toPath: string) =>
    state.userInfo && getters.canAccessRight(toPath),
  startDayOfWeek: (state: AppState) => {
    const { systemSetting: ss } = state
    return ss && typeof ss.startDayOfWeek === 'number' ? ss.startDayOfWeek : 0
  },
  /**
   * システムがメンテナンス状態か否か
   */
  isSystemMaintenance(state: AppState) {
    return state.systemStatus.isMaintenance
  },
  /**
   * システムが異常状態か否か（画面は継続利用）
   */
  isExtServiceWarn(state: AppState) {
    return state.systemStatus.isExtServiceWarn
  },
  isBelongStoresLoading(state: AppState) {
    return !state.loadingIds.indexOf('getBelongStores')
  },
  /**
   * 夜間処理で異常発生など、ユーザに通知すべきシステム異常が発生しているか否か
   */
  isSystemAlert(state: AppState) {
    // TODO システム仰臥位
    return false
  },
  /**
   * 取置機能利用不可フラグ
   */
  disableTorioki(state: AppState) {
    return state.systemSetting?.disableTorioki
  },
}

/**
 * 未認証用エラークラス
 * access token がない場合などはこちら
 */
class NotAuthorizedException extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NotAuthorizedException'
  }
}

const actions: ActionTree<AppState, RootState> = {
  /**
   * サーバーの状態取得
   */
  async getSystemStatus({ dispatch, commit, rootState }) {
    try {
      const result = await apolloClient.query<QuerySystemStatusResponse>({
        query: SystemStatusDocument,
      })
      const { systemStatus } = result.data
      if ('isImportWarn' in systemStatus && 'isMaintenance' in systemStatus) {
        commit('updateSystemStatus', systemStatus)
      } else {
        // 異常状態での返却値は異常扱い（メンテナンスモード移行）
        commit('updateSystemStatus', {
          isMaintenance: true,
          isImportWarn: false,
        })
      }
      return result
    } catch (e: unknown) {
      throw e as ApolloError
    }
  },

  /**
   * 店舗選択情報の変更
   */
  updateSelectedStoreCode({ commit }, { storeCode }) {
    // console.log(`action updateSelectedStoreCode, storeCode=${storeCode}`)
    commit('updateSelectedStoreCode', storeCode)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
