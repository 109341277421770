import {
  BImg,
  BContainer,
  BAvatar,
  BButton,
  BIcon,
  BBadge,
  BAlert,
  BBreadcrumb,
  BNavbar,
  BOverlay,
  BNavForm,
  BFormSelect,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BIconGear,
  BIconDoorClosed,
  BTable,
  BLink,
  BTh,
  BTr,
  BPagination,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatalist,
  BModal,
  BPopover,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BNavbarBrand,
  BToast,
  BForm,
  ModalPlugin,
  ToastPlugin,
  BIconDashCircle,
  BIconPlusCircle,
  BIconArrowClockwise,
  BIconBoxArrowUpRight,
  BIconExclamationCircle,
  BFormDatepicker,
  BFormRow,
  BFormCheckbox,
  BFormCheckboxGroup,
  BJumbotron,
} from 'bootstrap-vue'
import { CollapsePlugin } from 'bootstrap-vue'
import { FormRadioPlugin } from 'bootstrap-vue'
import { FormTextareaPlugin } from 'bootstrap-vue'
import { CalendarPlugin } from 'bootstrap-vue'
import { FormFilePlugin } from 'bootstrap-vue'
import { TabsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BSpinner } from 'bootstrap-vue'
import Vue from 'vue'

Vue.use(CollapsePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(CalendarPlugin)
Vue.use(FormFilePlugin)
Vue.use(TabsPlugin)
Vue.component('b-spinner', BSpinner)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-row', BFormRow)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-img', BImg)
Vue.component('b-container', BContainer)
Vue.component('b-avatar', BAvatar)
Vue.component('b-button', BButton)
Vue.component('b-icon', BIcon)
Vue.component('b-badge', BBadge)
Vue.component('b-alert', BAlert)
Vue.component('b-breadcrumb', BBreadcrumb)
Vue.component('b-overlay', BOverlay)
Vue.component('b-nav-form', BNavForm)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-collapse', BCollapse)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-icon-gear', BIconGear)
Vue.component('b-icon-door-closed', BIconDoorClosed)
Vue.component('b-table', BTable)
Vue.component('b-navbar', BNavbar)
Vue.component('b-link', BLink)
Vue.component('b-th', BTh)
Vue.component('b-tr', BTr)
Vue.component('b-pagination', BPagination)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-datalist', BFormDatalist)
Vue.component('b-modal', BModal)
Vue.component('b-popover', BPopover)
Vue.component('b-card', BCard)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-toast', BToast)
Vue.component('b-form', BForm)
Vue.component('BIconDashCircle', BIconDashCircle)
Vue.component('BIconPlusCircle', BIconPlusCircle)
Vue.component('BIconArrowClockwise', BIconArrowClockwise)
Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight)
Vue.component('BIconExclamationCircle', BIconExclamationCircle)
Vue.component('BJumbotron', BJumbotron)

Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
// Vue.use(BootstrapVue)
//Vue.use(BootstrapVueIcons)
