

















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import Toast from '@/utils/toast'

/**
 * メンテ・障害発生時の画面
 */
export default defineComponent({
  layout: 'none',
  setup() {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const { $store, $route, $router } = instance.proxy
    const appReload = () => location.reload()
    const logout = async () => {
      try {
        await $store.dispatch('auth/logout')
        const currentPath = $route.path
        const base = `/${currentPath.split('/')[1]}`
        if (base !== currentPath) {
          $router.push(base)
        }
      } catch (e) {
        Toast.error(instance.proxy, { message: '' + e })
      }
    }
    return {
      appReload,
      logout,
    }
  },
})
